<template>
  <div id="my-account">
    <v-flex xs12 v-if="!isMobile">
      <h1>{{ $t('account.title')}}</h1>

      <div class="item">
        <h2 @click="toPage('Order')"> {{ $t('account.purchase.title')}} </h2>
        <p class="text">
          {{ $t('account.purchase.subtitle')}}
        </p>
      </div>

      <div class="item" v-if="idBrand === '15'">
        <h2 @click="toPage('Confirm-Payment')"> {{ $t('account.confirm-payment.title')}} </h2>
        <p class="text">
          {{ $t('account.confirm-payment.subtitle')}}
        </p>
      </div>

      <div class="item">
        <h2 @click="toPage('Address')"> {{ $t('account.address.title')}} </h2>
        <p class="text">
          {{ $t('account.address.subtitle')}}
        </p>
      </div>

      <div class="item">
        <h2 @click="toPage('Info')"> {{ $t('account.personal-info.title')}} </h2>
        <p class="text">
          {{ $t('account.personal-info.subtitle')}}
        </p>
      </div>

      <div class="item">
        <h2 @click="toPage('Access-Data')"> {{ $t('account.access.title')}} </h2>
        <p class="text">
          {{ $t('account.access.subtitle')}}
        </p>
      </div>

      <div class="item">
        <h2 @click="toPage('Wishlist')"> {{ $t('wishlist.title')}} </h2>
        <p class="text">
          My Wishlist
        </p>
      </div>

      <!-- <div class="item">
        <h2 @click="toPage('MyReview')"> Notifications </h2>
        <p class="text">
          Stay informed by checking notifications
        </p>
      </div> -->

    </v-flex>
    <v-flex v-else>
      <ul>
        <li @click="toPage('Order')" class="uppercase"> {{ $t('account.purchase.title')}} </li>
        <li v-if="idBrand === '15'" @click="toPage('Confirm-Payment')" class="uppercase"> {{ $t('account.confirm-payment.title')}} </li>
        <li @click="toPage('Address')" class="uppercase"> {{ $t('account.address.title')}} </li>
        <li @click="toPage('Info')" class="uppercase"> {{ $t('account.personal-info.title')}} </li>
        <li @click="toPage('Access-Data')" class="uppercase"> {{ $t('account.access.title')}} </li>
        <li @click="toPage('Wishlist')" class="uppercase"> Wishlist </li>
        <!-- <li @click="openSheet()" class="uppercase"> Notifications </li> -->
        <li class="text-so" @click="logout()"> {{ $t('signout')}} </li>
      </ul>
    </v-flex>

    <v-bottom-sheet v-model="sheet">
      <NotificationMenu @close="sheet= false"/>
    </v-bottom-sheet>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import NotificationMenu from '@/components/Notification/NotificationMenu'

  export default {
    name: 'MyAccount',
    components: { NotificationMenu },
    computed: {
      ...mapState([
        'isMobile'
      ])
    },
    data () {
      return {
        sheet: false
      }
    },
    methods: {
      toPage (name) {
        if (this.$route.name !== name) {
          this.$router.push({ name: name });
        }
      },
      openSheet () {
        this.sheet = true
      }
    }, 
    mounted () {
      this.setMetaInfo(null, 'My Account')
      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsLoginPage', false)
      this.$store.commit('setIsMemberPage', false)
      this.$store.commit('setActivePage', this.$t('account.title'))
    }
  }
</script>

<style scoped>
  .item {
    color: #000000;
    margin: 30px 0 10px 0;
  }

  .text {
    color: #000000;
    font-size: 0.9rem !important;
  }

  h2:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  ul {
    list-style-type: none;
    padding: 0 !important;
  }

  ul > li {
    color: #000000;
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: 700;
    display: block;
    padding-bottom: 0.5em;
    margin-bottom: 20px;
    cursor: pointer;
  }
  
  .text-so {
    margin-top: 40px !important;
    font-weight: normal !important;
  }

  .uppercase {
    text-transform: uppercase;
  }

</style>
