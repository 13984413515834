<template>
  <div class="notif-menu">
    <div class="nm-header">
      <span class="nm-title"> NOTIFICATIONS</span>
      <v-icon class="nm-header-icon" color="#000" @click="$emit('close')">
        mdi-close
      </v-icon>
    </div>
    <v-divider class="my-2"></v-divider>
    <div class="nm-content">
      <div class="nm-content-wrapper" @click="toPage('MyReview')">
        <span class="nm-title"> REVIEWS</span>
        <v-icon class="nm-header-icon" color="#000">
          mdi-chevron-right
        </v-icon>
      </div>
      <v-divider class="my-2"></v-divider>
      <div class="nm-content-wrapper" @click="toPage('MyDiscussion')">
        <span class="nm-title"> DISCUSSIONS</span>
        <v-icon class="nm-header-icon" color="#000">
          mdi-chevron-right
        </v-icon>
      </div>
       <v-divider class="my-2"></v-divider>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotifMenu',
  methods: {
    toPage (link) {
      this.$router.push({ name: link})
    }
  }
}
</script>

<style scoped>
.notif-menu {
  padding: 20px 12px;
  text-align: center;
  height: 200px;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.nm-header {
  position: relative;
  padding: 8px 0;
}
.nm-header-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
.nm-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}
.nm-content {
  text-align: left;
}
.nm-content-wrapper {
  position: relative;
  padding: 8px 0;
}
</style>